.title-tooltip {
    display: flex;
    position: relative;
}

.tooltip-icon {
    width: 15px;
}

.tooltip-container {
    margin-left: 10px;
    color: white;
}

.tooltip-container>.tooltiptext {
    visibility: hidden;
    font-size: 12px;
    background-color: var(--midnight);
    text-align: justify;
    padding: 12px 8px;
    width: 170px;
    position: absolute;
    top: 27px;
    margin-left: -70px;
    opacity: 0;
    transition: opacity 0.3s;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    z-index: 9999;
}

.tooltip-container .tooltiptext::before {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 70px;
    margin-left: -7px;
    border-width: 7px;
    border-style: solid;
    border-color: transparent transparent var(--midnight) var(--midnight);
}

.tooltip-container:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}
