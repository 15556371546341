#sidebar {
    background-color: var(--midnight);
    color: white;
    display: flex;
    flex-direction: column;
    border: var(--curious-blue) 1px solid;
    box-shadow: -10px 10px 10px rgb(182, 182, 182);
}

#download-options-select {
    background-color: #2A3A54;
    padding: 0 10px;
}

#download-options-select .dowload-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    font-weight: bold;
    padding: 8px 0;
}

#download-options-select .dowload-option>img {
    width: 15px;
}

#download-options-select .dowload-option:hover>img {
    filter: invert(54%) sepia(13%) saturate(5290%) hue-rotate(168deg) brightness(92%) contrast(81%);
}

#download-options-select .dowload-option:hover {
    color: var(--curious-blue);
    cursor: pointer;
}

#navigation-panel {
    margin-top: 20px;
}

#navigation-panel ul {
    list-style-type: none;
}

.menu-item {
    min-width: 140px;
    padding-left: 30px;
    height: 30px;
    display: flex;
}

.menu-item.selected {
    background-color: #2A3A54;
}

.menu-item.selected span {
    background-color: #2A3A54;
    color: #52A628; /*TODO: use color variable*/
}

.menu-item>a {
    color: white;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.menu-item span {
    font-size: 14px;
    font-weight: 500;
    vertical-align: top;
}

.menu-item span:hover {
    color: #52A628; /*TODO: use color variable*/
}

.menu-item img {
    width: 15px;
    margin-right: 20px;
}

.sidebar-footer {
    margin-top: auto;
    display: flex;
    flex-direction: column;
}

#profile {
    display: flex;
    align-items: center;
    background-color: #2A3A54;
    margin: 30px 0;
    max-width: 100%;
    overflow: hidden;
    width: 100%; /* Ensure full width */
}

#profile>img {
    background-color: white;
    width: 40px;
}

#profile #profile-button {
    color: white;
    padding: 0 5px;
    background-color: #2A3A54;
    font-size: 9px;
}

#profile #profile-button:hover {
    color: #52A628;
    text-decoration: none;
}

#profile #logout-button {
    justify-self: flex-end;
    height: 40px;
    width: 40px;
    border: none;
    background-color: var(--bilbao);
    flex-shrink: 0;
}

#profile #logout-button:hover {
    background-color: #52A628;
}

#greetings {
    flex-grow: 1;
    background-color: #2A3A54;
    padding: 0 5px;
    min-width: 0; /* Critical for text truncation in flex */
}

#sidebar #tools {
    display: flex;
    justify-content: space-between;
    background-color: #2A3A54;
    padding: 6px 0
}

#sidebar #tools #language-togggle{
    margin-right: 25px;
}

#greetings>p {
    font-size: 12px;
}

#greetings #greetings-user-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    margin-top: 4px;
}

#sidebar #tools #about-button {
    background-color: var(--bilbao);
    text-align: center;
    vertical-align: middle;
    line-height: 30px;
    width: 28px;
    height: 28px;
    font-size: 20px;
    font-weight: 600;
    margin-left: 25px;
}

#about-button:hover {
    cursor: pointer;
    background-color: #52A628; /*TODO: use color variable*/
}

#language-button {
    display: flex;
    flex-direction: row;
    background-color: var(--bilbao);
    margin-left: auto;
}

#language-button:hover {
    background-color: #52A628; /*TODO: use color variable*/
    cursor: pointer;
}

#language-button>p {
    margin: 0;
    text-align: center;
    vertical-align: middle;
    line-height: 30px;
}

#logo {
    display: flex;
}

#logo>img {
    width: 50px;
    margin: 20px auto;
}

#about-modal>.info-modal-body {
    margin-top: -120px;
    background-color: #F2F2F2;
    padding: 20px;
    border: 1px solid #F2F2F2;
    box-shadow: 0 1px 14px rgba(0,0,0,.2);
    color: #616161;
    font-size: 13px;
    position: absolute;
    margin-bottom: 150px;
    margin-left: 20px;
    left: 0px;
}

#about-modal>.info-modal-body:after {
    content: '';
    position: absolute;
    margin-left: -8px;
    border-width: 11px 8px 11px 8px;
    border-style: solid;
    border-color: #F2F2F2 transparent transparent transparent;
    top: 111px;
    left: 19%;
}

#about-modal h2 {
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 2px;
}

.info-modal-content {
    color: #00142B;
    display: flex;
}

.info-modal-content button {
    margin: 13px auto;

}

#about-modal .info-modal-content>div>* {
    margin-bottom: 6px;
}

#linked-in-link {
    width: 23px;
    height: 23px;
    border: 1px solid var(--curious-blue);
    margin-top: auto;
    margin-left: 12px;
}

#linked-in-link>img {
    width: 100%;
    padding: 7px 5px 0 4px;
}