#id-connect.login-id-connect {
  position: absolute;
  right: 20px;
  top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#id-connect div#elevator-id-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 3px 3px 6px #00000029;
}

#id-connect.sidebar-id-connect #elevatator-id-info {
  height: 40px;
  width: 40px;
  background-color: var(--bilbao);
  display: flex;
  align-items: center;
  justify-content: center;
}

#id-connect.sidebar-id-connect input#elevator-id {
  color: white;
  background-color: #2A3A54;
  flex-grow: 1;
}

#id-connect.sidebar-id-connect input#elevator-id::placeholder {
  color: white;
  opacity: 0.75;
}


#id-connect.sidebar-id-connect #elevator-id-container #elevatator-id-info>img {
  height: 20px;
}


#id-connect.sidebar-id-connect #elevator-id-container {
  width: 100%;
  justify-content: space-between;
}

#id-connect.login-id-connect h1 {
  margin-right: 15px;
  font-size: 20px;
  color: var(--midnight);
  font-weight: 500;
  text-align: left;
}

#id-connect label#elevator-id-label {
  background-color: var(--bilbao);
  color: white;
  font-size: 12px;
  font-weight: bold;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 5px 30px;
  text-align: center;
}

#id-connect input#elevator-id {
  height: 40px;
  width: 100px;
  border: none;
  text-align: center;
  font-size: 14px;
  padding: 5px 10px;
  background-color: var(--concrete);
  color: var(--midnight);
  outline: none;
}

#connect-button {
  background-color: var(--bilbao);
  width: 40px;
  height: 40px;
  outline: none;
  border: none;
}

#connect-button:hover {
  background-color: #52A628;
}

#connect-button img {
  height: 18.4px;
  width: 20px;
}