#main-content {
    flex-grow: 1;
    border: var(--bilbao) 1px solid;
    box-shadow: 0px 10px 10px #00072B29;
    display: flex;
    flex-direction: column;
}

#main-content-header {
    border-bottom: var(--bilbao) 1px solid;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#main-content-window {
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--bilbao) white;
    height: 100%;
}

#main-content-window:has(#contractor-page) {   
    width: 880px;
}

#main-content-header>h1 {
    margin: 25px 40px;
    font-size: 22px;
    font-weight: 600;
}

#main-content #main-content-header #header-buttons {
    display: flex;
}