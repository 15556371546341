#right-panel {
    display: flex;
    flex-direction: column;
    width: 360px;
}

#right-panel.edit-visual-pannel>.panel-banner {
    align-items: flex-start;
}

#right-panel #panel-content.full-panel-properties>div {
    max-height: 100%;
}

.full-panel-properties #item-content .property-title {
    margin: 0 0 20px;
}

.property-title {
    color: var(--midnight);
    font-size: 14px;
    font-weight: 500;
    border-bottom: 1px solid #7F7F7F;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

#right-panel .form-group-input, #right-panel .titreInput, #right-panel .form-control {
    all: unset;
}

#visual-content #right-panel #panel-content .property-options h5 {
    margin-bottom: 10px;
    font-size: 12px;
}

#visual-content #right-panel .property-group {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

#visual-content #right-panel .property-group>div {
    margin-right: 10px;
    font-size: 12px;
}

#visual-content #right-panel .horizontal-property, #right-panel .form-group-input {
    display: flex;
    align-items: center;
}

#visual-content #right-panel .property-label, #visual-content #right-panel .titreInput {
    margin-right: 10px;
    padding: 6px 0px;
    text-wrap: nowrap;
    margin-top: auto;
    margin-bottom: auto;
}

#visual-content #right-panel .property-group input {
    margin-top: auto;
    margin-bottom: auto;
}

#visual-content #right-panel .property-value {
    background-color: #F2F2F2;
    padding: 6px 10px 6px 10px;
}

#right-panel .form-control {
    background-color: #F2F2F2;
    padding: 5px 5px 5px 10px;
}

#right-panel .small-input .form-control {
    max-width: 45px;
    margin-bottom: 15px;
}

#visual-content #right-panel .select-control {
    height: 25px;
    width: 130px;
}

#visual-content #right-panel .small-input .select-control {
    width: 65px;
}


#right-panel .property-options .tooltip-icon {
    width: 12px;
}

#right-panel .tooltip-container>.tooltiptext {
    margin-left: -114px;
}

#right-panel .tooltip-container .tooltiptext::before {
    left: 114px;
}

#right-panel #playlist-options .tooltip-container>.tooltiptext {
    margin-left: -114px;
}

#right-panel #playlist-options .tooltip-container .tooltiptext::before {
    left: 114px;
}

#right-panel.edit-visual-pannel .modal-content {
    height: 100%;
}

#right-panel.edit-visual-pannel #panel-content>div>.modal-overlay>.modal {
    max-width: 90%;
    width: 90%;
    max-height: 95%;
    height: 95%;
}

#right-panel #panel-content {
    display: flex;
    flex-direction: column;
    height: 100%;
}

#right-panel #panel-content>div {
    flex: 1;
    max-height: 50%;
    scrollbar-width: thin;
    scrollbar-color: var(--bilbao) white;
}

#right-panel #panel-content>div:hover {
    scrollbar-color: #52A628 white;
}

#right-panel #panel-content .property-options {
    max-height: 100%;
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: var(--bilbao) white;
    padding: 20px;
}

#right-panel .modal-body {
    overflow: hidden;
}

#right-panel #panel-content .modal .property-options {
    padding: 0;
    overflow: unset;
}

#right-panel #panel-content input[type="color"] {
    max-width: 50px;
}