.slider-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.slider-container .switch {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 17px;
}

.slider-container .switch:hover {
    cursor: pointer;
}

.slider-container .switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider-container .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #7F7F7F;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider-container .slider:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider-container input:checked + .slider {
    background-color: green;
}

.slider-container input:checked + .slider:before {
    -webkit-transform: translateX(19px);
    -ms-transform: translateX(19px);
    transform: translateX(19px);
}

.slider-container .slider-warning {
    color: #C21123;
    font-size: 12px;
    margin-top: 3px;
    text-align: left;
}