#layout {
    display: flex;
    flex-direction: column;
    height: 100dvh;
}

#sidebar {
    width: 15%;
    min-width: 200px;
}

#main-content {
    width: 85%;
}

#main {
    display: flex;
    flex-grow: 1;
    padding: 20px;
    gap: 20px;
    height: 100%;
}

#footer {
    background-color: #00142B; /*TODO: use color variable*/
    color: white;
    margin-top:auto;
}
