/*temporary*/
.create-responder-modal .modal, .edit-responder-modal .modal {
  width: 700px;
  min-width: 700px;
  max-height: 95%;
  height: 900px;
  overflow-y: auto;
}

#schedule {
  max-height: 550px;
  overflow-y: auto;
}