.left-panel-window {
    display: flex;
    flex-direction: column;
    height: 100%;
}

#left-panel {
    display: flex;
    flex-direction: column;
}

#left-panel>.left-panel-window {
    flex: 1;
    max-height: 50%;
}

.left-panel-content {
    height: 100%;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--bilbao) white;
}

.left-panel-content:hover {
    scrollbar-color: #52A628 white;
}

#screen-pages li:hover {
    color: var(--bilbao);
    cursor: pointer;
}

#screen-pages>div {
    display: flex;
}

#screen-pages .page-number {
    font-size: 14px;
    font-weight: 500;
}

.left-panel-content {
    display: flex;
    overflow-x: hidden;
}

button.add-page-button {
    all:unset;
    color: #fff;
    background-color: var(--bilbao);
    display: flex;
    width: 24px;
    height: 24px;
    margin-right: 20px;
}

button.add-page-button:hover {
    background-color: #52A628;
    cursor: pointer;
}

.add-page-button>img {
    margin:4px;
    height: 16px;
}

.left-panel-item {
    width: 100%;
    margin: 10px 20px;
}

.page-item {
    border-bottom: #7F7F7F solid 1px;
    font-weight: normal;
}

.page-item, #left-panel .content-category>div {
    display: flex;
    margin-left: 5px;
    justify-content: space-between;
    align-items: center;
}

#screen-pages .left-panel-content .left-panel-item .page-item-info {
    display: flex;
    align-items: center;
}

#screen-pages .left-panel-content .left-panel-item .page-item-info .page-info {
    display: flex;
    align-items: baseline;
    gap: 4px;
}


#screen-pages .left-panel-content .left-panel-item .page-item-info > p {
    display: inline;
    margin: 0;
}

#screen-pages .left-panel-content .left-panel-item .drag-icon {
    padding: 8px 8px 8px 0;
    margin: 0;
    cursor: grab;
}

#screen-pages .left-panel-content .left-panel-item .drag-icon>div{
    width: 10.76px;
    height: 2px;
    background-color: #00142B;
    margin: 2px 0;
}

#visual-content #left-panel li {
    color: var(--midnight);
}

#left-panel .content-category {
    font-weight: bold;
    cursor: pointer;
    border-bottom: #7F7F7F solid 1px;
}

#left-panel .category-item {
    font-weight: normal;
    display: flex;
    margin-left: 5px;
    justify-content: space-between;
    align-items: center;
}

#visual-content #left-panel li.selected-item {
    color: var(--bilbao);
}

#visual-content #left-panel li.unavailable-item {
    color: var(--error-red);
}

#left-panel .category-details-button {
    all:unset;
}

#left-panel .delete-icon-container {
    border-left: #F2F2F2 solid 3px;
    height: 25px;
    margin: 5px 0 5px 4px;
    display: flex;
}

#left-panel .delete-icon-container>button {
    all: unset;
    height: 15px;
    margin: auto 2px auto 20px;
}

#left-panel .delete-icon-container>button>img {
    height: 100%;
}

#left-panel .delete-icon-container>button>img:hover {
    filter: invert(37%) sepia(44%) saturate(706%) hue-rotate(56deg) brightness(100%) contrast(97%);
}

#left-panel .category-actions {
    display: flex;
    align-items: center;
    margin-left: auto;
}

#visual-content #left-panel .category-name {
    font-size: 14px;
}

/* #visual-content #left-panel .item-name {
    display: flex;
} */

#left-panel .title-tooltip {
    align-items: center;
}