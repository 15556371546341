
#contractor-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 25px;
}

#contractor-form .name-input {
    order: 3;
}

#contractor-form .address-input {
    order: 5;
}

#contractor-form .city-input {
    order: 7;
}

#contractor-form .state-input {
    order: 2;
}

#contractor-form .postal-code-input {
    order: 4;
}

#contractor-form .country-input {
    order: 6;
}

#contractor-form .vidatech-button {
    margin-left: auto;
    margin-top: auto;
    order: 8;
}

#contractor-form .select-user-type {
    /* margin-left: auto; */
    order: 1;
}

#contractor-page h1 {
    font-size: 40px;
    font-weight: 900;
    font-family: 'Roboto', sans-serif;
    margin: 25px;
}
