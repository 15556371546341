#right-panel #panel-content.travel-info-properties>div {
    max-height: 100%;
}

#right-panel #panel-content.travel-info-properties #item-content .property-title {
    margin: 0 0 20px 0;
}

#right-panel #panel-content.travel-info-properties #item-content .titreInput {
    width: 50%;
}

#right-panel #panel-content.travel-info-properties #item-content .form-group-input > *{
    margin-bottom: 10px;
}

#right-panel #panel-content.travel-info-properties #item-content .form-group-input > *{
    margin-bottom: 10px;
}

#right-panel #panel-content.travel-info-properties #item-content .form-group-input .select-control {
    height: 25px;
    width: 130px;
}
