.main-content-footer {
    border-top: var(--bilbao) 2px solid;
    display: flex;
    flex-direction: column;
    margin-top: auto;
}

.main-content-footer>div {
    padding-left: 25px;
    padding-right: 25px;
}

.main-content-footer>.settings-title>h1 {
    padding-top: 27px;
    padding-bottom: 2px;
    font-size: 16px;
    font-weight: 600;
    border-bottom: 1px solid #7F7F7F;
}

.main-content-footer .form-group-input {
    display: flex;
    margin: 20px 0 0;
}

.main-content-footer .form-control {
    width: 100px;
    margin-left: 5px;
}

#callees-modal-content {
    width: 100%;
    height: 100%;
}

#callees-modal-content .create-button {
    margin-left: auto;
}

#callees-modal .modal-content {
    height: 100%;
}

#callees-modal-content .confirm-modal .modal, #callees-modal-content .error-modal .modal {
    width: 600px;
    height: 250px;
}
#callees-modal-content .confirm-modal .modal button.vidatech-button {
    margin: 0 5px;
}

.callee-form {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
}

.callee-form:hover {
    scrollbar-color: #52A628 white;
}

.main-content-footer .titreInput {
    margin: 0;
    line-height: 40px;
}

.main-content-footer .vidatech-button {
    float: right;
    margin-bottom: 20px;
}

.calleeCardForms-container {
    display: grid;
    grid-template-columns: 50% 50%;
    padding-bottom: 20px;
}

.card-fields {
    border-left: 3px solid #F2F2F2;
    border-right: 3px solid #F2F2F2;
    padding-right: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card-fields .form-group-input {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    width: 100%;
}

.card-fields .form-control, .card-fields .select-control {
    width: 290px;
    height: 35px;
}

.priority {
    background-color: #00142B;
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    color: white;
    font-size: 15px;
}
/* TEST*/
.priority p {
    margin: 0;
    padding: 0;
    padding-top: 3px;
    font-weight: bold;
    text-align: center;
}

.callee-form .drag-icon {
    padding: 50px 8px;
    margin: 0;
    cursor: grab;
}

.callee-form .drag-icon>div {
    width: 10.76px;
    height: 2px;
    background-color: #00142B;
    margin: 2px 0;
}

.card-fields .titreInput {
    margin: auto 8px auto 18px;
}
