.vidatech-button {
    cursor: pointer;
    background-color: var(--bilbao);
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    color: white;
    border: none;
    margin: 10px 0px 20px 0;
    height: 40px;
    width: 120px;
    justify-content: center;
}

.vidatech-button:hover {
    background-color: #52A628;
}

button.vidatech-button[disabled] {
    pointer-events: none;
    opacity: 0.5;
}