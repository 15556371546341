.appStyle{
  font-family: 'Roboto', sans-serif;
    
  }
  body{
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    --primary-green-color: #73b74e;
    --primary-darkgreen-color: #588b3c;
    --primary-darkblue-color: #00142b;
  }
  
  
  .noselect {
    -webkit-user-select: none;    /* Safari */
    -webkit-touch-callout: none;  /* iOS Safari */
    -khtml-user-select: none;     /* Konqueror HTML */
    -ms-user-select: none;        /* Internet Explorer/Edge */
    -moz-user-select: none;       /* Old versions of Firefox */
     user-select: none;           /* Non-prefixed version*/
  }
