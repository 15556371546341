#add-position-indicator-form .modal {
    height: 500px;
    width: 400px;

}

#visual-content #add-position-indicator-form .modal .modal-body {
    align-items: flex-end;
}

#visual-content #add-position-indicator-form .form-control,
#visual-content #add-position-indicator-form .form-group-input {
    width: 100%;
}