#sidebar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #2A3A54;
    width: 100%;
    max-height: 75px;
}

#sidebar-header>#back-button {
    color: white;
    background-color: var(--bilbao);
    cursor: pointer;
    display: flex;
    min-width: 45px;
    height: 100%;
    min-height: 55px;
}

#sidebar-header>#back-button:hover {
    background-color: #52A628;
}

#sidebar-header>#back-button img {
    width: 20px;
    height: 20px;
    margin:auto;
}

#building-info {
    margin: 5px 10px;
    flex-grow: 1;
}

#building-info>h1 {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.2em;
    margin: 0 0 5px 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: anywhere;
}

#building-info>p {
    font-size: 11px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: anywhere;
}

.skeleton {
    width: 110px;
    height: 14px;
    margin: 8px 0;
    background: linear-gradient(90deg, #eaeaea 25%, #f0f0f0 50%, #e0e0e0 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
    0% {
        background-position: -200% 0;
    }
    100% {
        background-position: 200% 0;
    }
}

#sidebar-header #download-config-button {
    outline: none;
    border: none;
    background-color: var(--bilbao);
    height: 100%;
    width: 30px;
}


#sidebar-header #download-config-button img {
    width: 12px;
}

