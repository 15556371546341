@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
@import url("https://fonts.googleapis.com/css?family=Roboto:400,300,300italic,500,700,900");
/* The following line is used to measure usage of this code. You can remove it if you want. */



.page-1-1440x900-se-connecter-1 {
  align-items: flex-start;
  background-color:var(--white);
  display: flex;
  flex-direction: column;
  gap: 261px;
  height: 900px;
  padding: 19px;
  width: 1440px;
}

body{
 background-color: white;
 line-height: 1;
}
*{
  box-sizing: border-box;
}

@font-face {
  font-family: "Arial-Bold";
  font-style: normal;
  font-weight: 700;
  src: url("https://fonts.animaapp.com/Arial-Bold") format("truetype");
}