.property-options .form-group-input.has-error {
    display: inline;
}

.property-options .form-group-input.has-error>.error-messages {
    flex-direction: column;
}

#item-content {
    display: flex;
    flex-direction: column;
}

#playlist-options {
    display: flex;
}

/* #right-panel #panel-content>#playlist-options>.property-options {
    max-height: 100%;
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: var(--bilbao) white;
    padding: 20px;
} */

#right-panel .property-options:hover {
    scrollbar-color: #52A628 white;
}

#item-content .property-title {
    margin: 20px;
}

#right-panel .modal-content .property-options {
    padding: 0;
}

#right-panel .modal-content .property-title {
    margin: 20px 0;
}

#item-content #playlist-images-list {
    flex-grow: 2;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 20px;
    scrollbar-width: thin;
    scrollbar-color: var(--bilbao) white;
}

#item-content #playlist-images-list:hover {
    scrollbar-color: #52A628 white;
}

#item-content .vidatech-button {
    margin: 0 auto;
}

#item-content #playlist-images-list {
    padding: 0 20px;
}
