:root { 
  --bilbao: #3d7e1c;
  --concrete: #f2f2f2;
  --curious-blue: #209ad6;
  --la-palma: #42871f;
  --midnight: #00142b;
  --white: #ffffff;
  --error-red: #C21123;
 
  --font-size-l: 14px;
  --font-size-m: 12px;
  --font-size-xl: 20px;
  --font-size-xxl: 40px;
 
  --font-family-roboto: "Roboto", Helvetica;
}
.titre---roboto---14pt {
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0;
}

.texte-courant---roboto--12pt {
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
}

.texte-courant---roboto--14pt {
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
}

.roboto-bold-white-12px {
  color: var(--white);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.roboto-medium-midnight-20px {
  color: var(--midnight);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 500;
}

.roboto-normal-white-14px {
  color: var(--white);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-l);
}

.roboto-bold-white-14px {
  color: var(--white);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.roboto-light-midnight-12px {
  color: var(--midnight);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-m);
  font-style: italic;
  font-weight: 300;
}
