#file-explorer>#file-explorer-content #content-view .item-action-window {
    position: absolute;
    top: 20px;
    right: -45px;
    z-index: 999;
    font-size: 12px;
    background-color: #F2F2F2;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.205);
}

.icon button.item-action-option {
    display: flex;
    border: none;
    background-color: transparent;
    align-items: center;
    gap: 5px;
    margin: 5px 0;
}

.icon button.item-action-option:hover {
    color: var(--bilbao);
}

.icon button.item-action-option:hover img {
    filter: invert(36%) sepia(83%) saturate(3359%) hue-rotate(73deg) brightness(87%) contrast(78%);
}

#file-explorer .icon button.item-action-option img {
    height: 10px;
}

#file-explorer>#file-explorer-content #content-view>.icon {
    position: relative;
    width: 100px;
    height: 124px;
    margin: 10px;
    text-align: center;
    padding: 10px;
    border: 1px solid transparent;
    transition: color 0.15s ease-in-out, border 0.15s ease-in-out;
}

#file-explorer>#file-explorer-content #content-view>div.icon>button.item-action-button {
    position: absolute;
    border: none;
    background-color: transparent;
    top: 0px;
    right: 0px;
    display: none;
}

#file-explorer>#file-explorer-content #content-view>div.icon.selected>button.item-action-button {
    display: block;
}

#file-explorer>#file-explorer-content #content-view>div.icon:hover>button.item-action-button {
    display: block;
}

#file-explorer>#file-explorer-content #content-view>div.icon>button.item-action-button img{
    height: 10px;
}

#file-explorer>#file-explorer-content #content-view .icon-info {
    all:unset;
    cursor: pointer;
    width: 80px;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    height: 100%;
}

#file-explorer>#file-explorer-content #content-view .icon-info .image-container {
    height: 50%;
    display: flex;
}

#file-explorer>#file-explorer-content #content-view .icon-info .image-container img {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
}

#file-explorer>#file-explorer-content #content-view>div.icon>button:focus {
    outline: none;
}


#file-explorer>#file-explorer-content #content-view .icon.selected {
    border: 1px solid var(--bilbao);
    color: var(--bilbao);
}

#file-explorer>#file-explorer-content #content-view .icon-info:hover p{
    color: var(--bilbao);
}

#file-explorer>#file-explorer-content>div>#content-view .file-name {
    font-size: 12px;
    margin-top: 15px;
    word-wrap: break-word;
    max-width: 100%;
    flex: 1;
}

[data-tooltip] {
	position: relative;
	cursor: help;
	text-decoration: underline;

    &[data-position="bottom"]::before {
        top: 150%;
        transform: translateY(-20px);
    }
    &:hover::before {
		transform: translate(0);
		opacity: 1;
	}
	
	&::before {
		content: attr(data-tooltip);
		position: absolute;
        word-wrap: break-word;
        max-width: 250px;
		display: block;
		background: #FFF;
		padding: 10px;
		top: -50px;
		box-shadow: 0px 2px 5px #0000008c;
		left: -35px;
		z-index: 1;
		opacity: 0;
		pointer-events: none;
		transform: translateY(20px);
	  	transition: all .3s cubic-bezier(.4, 0, .2, 1);
	}
}

#selected-item-name [data-tooltip]::before {
    left: 0;
}