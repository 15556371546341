.user-form .vidatech-button {
    float: right;
}

.user-form-modal .modal{
    height: 420px;
    width: 450px;
}

.user-form-modal .modal-content{
    width: 390px;
}

.user-form-modal .modal-banner h1 {
    margin-right: 5px;
    text-transform: uppercase;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}