#visual-content #add-page-form {
    position: fixed;
}

#visual-content #add-page-form .modal {
    height: 260px;
    width: 400px;
}

#visual-content #add-page-form .modal .modal-body {
    align-items: flex-end;
}

#visual-content #add-page-form .modal .modal-content {
    align-items: flex-start;
}

#visual-content #add-page-form .form-control,
#visual-content #add-page-form .form-group-input {
    width: 100%;
}