.form-visual-modal .modal {
    width: 450px;
    height: 410px;
}


#visual-form {
    display: flex;
    flex-direction: column;
}

#visual-form .vidatech-button {
    margin-left: auto;
}