#visual-content #right-panel .image-info {
    display: flex;
    justify-content: space-between;
    margin-left: 20px;
    margin-right: 20px;
}

#visual-content #right-panel #playlist-images-list .image-info {
    margin: 5px auto;
}

#visual-content #right-panel .image-info>.image-details {
    display: flex;
    align-items: center;
}

#visual-content #right-panel .image-info button {
    all:unset;
}

#visual-content #right-panel .image-details .image-name {
    margin-left: 12px;
    max-width: 150px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

#visual-content #right-panel .image-details>.image-name.unavailable-item {
    color: red;
}

#visual-content #right-panel .image-details:has(button):hover {
    cursor: pointer;
    color: var(--bilbao);
}

#visual-content #right-panel .image-details .image-name.selected-item {
    color: var(--bilbao);
}

#visual-content #right-panel .image-details .image-name.unavailable-item {
    color: var(--error-red);
}

#visual-content #right-panel .image-details .image-name.unavailable-item:hover {
    color: var(--error-red);
}

#visual-content #right-panel .image-info .image-preview-container>img {
    border: 1px solid #F2F2F2;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center center;
}

#visual-content #right-panel .image-preview-container {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    overflow: hidden;
    position: relative;
}

#visual-content #right-panel .image-info .delete-icon-container {
    border-left: #F2F2F2 solid 3px;
    height: 25px;
    margin: 7px 0 7px 4px;
    display: flex;
}

#visual-content #right-panel .image-info .delete-icon-container>button {
    all: unset;
    height: 15px;
    margin: auto 2px auto 20px;
}

#visual-content #right-panel .image-info .delete-icon-container>button>img {
    height: 100%;
}

#visual-content #right-panel .image-info .delete-icon-container>button>img:hover {
    filter: invert(37%) sepia(44%) saturate(706%) hue-rotate(56deg) brightness(100%) contrast(97%);
    cursor: pointer;
}

#visual-content #right-panel .image-info .delete-icon-container>button:focus {
    outline: 2px solid var(--bilbao);
    filter: invert(37%) sepia(44%) saturate(706%) hue-rotate(56deg) brightness(100%) contrast(97%);
}

#visual-content #right-panel .drag-icon {
    padding: 8px 8px 8px 0;
    margin: 0;
    cursor: grab;
}

#visual-content #right-panel .drag-icon>div {
    width: 10.76px;
    height: 2px;
    background-color: #00142B;
    margin: 2px 0;
}