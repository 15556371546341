.create-button {
    display: flex;
    cursor: pointer;
    margin-right: 20px;
}

.create-button .vidatech-button {
    margin:0;
    width: 128px;
}

.create-button.disabled {
    pointer-events: none;
}

.create-button.disabled .plus-sign {
    opacity: 0.5;
}

.create-button>.vidatech-button:hover {
    background-color: unset;
}

.create-button:hover .vidatech-button, .create-button:hover .plus-sign {
    background-color: #52A628;
}

.plus-sign {
    color: #fff;
    background-color: #3D7E1C;
    margin-left: 2px;
    display: flex;
    width: 40px;
    height: 40px;
}

.plus-sign>img {
    margin:auto;
}

.create-button .content-select {
    position: absolute;
    background-color: #F2F2F2;
    top: 76px;
    right: 42px;
    min-width: 169px;
    box-shadow: 0 3px 6px #00000029;
    z-index: 999;
}

.create-button .content-select .content-option {
    padding: 8px 24px;
    cursor: pointer;
    font-size: 12px;
}

.create-button .content-select .content-option:hover {
    color: var(--bilbao);
}