
#vidatech-admin-content {
    display: flex;
    padding: 30px 20px;
    gap: 50px;
}

#vidatech-admin-content #automation #automation-form {
    margin-top: 20px;
}

#vidatech-admin-content #automation .form-control {
    margin: 10px 10px 0 0;
    width: 200px;
}

#vidatech-admin-buildings {
    width: 100%;
}

#vidatech-admin-buildings .items-table-container {
    width: 100%;
    overflow-x: auto;
    scrollbar-width: thin;
}

#vidatech-admin-buildings > .search-input, #vidatech-admin-buildings > .no-result {
    margin: 0 20px;  
}

#transfer-ownership {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

#transfer-header {
    display: flex;
    align-items: center;
}

#transfer-header button {
    margin-right: 10px;
}

#take-control-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

#transfer-ownership-form {
    display: flex;
    flex-direction: column;
}

#transfer-ownership-form>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#transfer-ownership-form>div>div {
    display: flex;
    align-items: center;
}

#vidatech-admin-content .current-user {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

#vidatech-admin-content .user-select {
    display: flex;
    align-items: center;
}

#vidatech-admin-content .user-selection {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#vidatech-admin-content .user-selection>div {
    display: flex;
}

#take-control-form .titreInput {
    margin: 0;
}

#take-control-form .checkbox-container {
    margin: 0;
}

#take-control-form input[type="checkbox"] {
    margin: auto 10px auto 0;
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    margin-right: 0.5em;
    border: 2px solid #3D7E1C;
    outline: none;
    cursor: pointer;
}

#take-control-form .checkbox-container input.checked {
    border: 2px solid #209AD6;
    position: relative;
}
  
#take-control-form .checkbox-container input.checked::before {
    content: "";
    display: block;
    background-color: #209AD6;
    height: 66%;
    width: 66%;
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translate(50%, -50%);
}

#vidatech-admin-content .select-control {
    color: #00142b;
    font-family: "Roboto", Helvetica;
    font-size: 12px;
    padding-left: 20px;
    font-weight: 400;
    background-color: #f2f2f2;
    height: 40px;
    display: block;
    border: none;
    cursor: pointer;
}

#vidatech-admin-content .user-select .select-control.disabled {
    color: #a5a5a5;
}

#vidatech-admin-content .user-select .select-control.disabled:hover {
    cursor: default;
}

.user-selection-spinner {
    width: 16px;
    margin: 0 10px;
}

.user-selection-spinner.visible {
    width: 16px;
    height: 16px;
    border: 2px solid;
    border-color: var(--bilbao) transparent var(--bilbao) transparent;
    border-radius: 50%;
    animation: spin-anim 1.2s linear infinite;
}

#vidatech-admin-content form .vidatech-button {
    margin-left: auto;
}

#vidatech-admin-buildings .table-cell {
    padding: 9px 6px;
}

#vidatech-admin-buildings .cell-container.large {
    min-width: 240px;
    max-width: 240px;
}

#vidatech-admin-buildings .cell-container.medium {
    min-width: 190px;
    max-width: 190px;
}

#vidatech-admin-buildings .cell-container .table-cell {
    max-width: 100%;
}

#vidatech-admin-buildings .cell-container.medium {
    min-width: 120px;
    max-width: 120px;
}

#vidatech-admin-buildings .cell-container .table-cell .microtik-password {
    display: flex;
    align-items: center;
}

#vidatech-admin-buildings .cell-container .table-cell .microtik-password .password-icon {
    position: unset;
    transform: unset;
    padding-left: 4px;
}

#vidatech-admin-buildings .cell-container .table-cell .microtik-password .password {
    font-family: monospace;
    display: inline-flex;
    gap: 1px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  
  #vidatech-admin-buildings .cell-container .table-cell .microtik-password .password.loading span {
    display: inline-block;
    opacity: 0;
    animation: fade-in-out 0.5s infinite;
  }
  
  #vidatech-admin-buildings .cell-container .table-cell .microtik-password .password.loading span:nth-child(1) {
    animation-delay: 0s;
  }
  
  #vidatech-admin-buildings .cell-container .table-cell .microtik-password .password.loading span:nth-child(2) {
    animation-delay: 0.1s;
  }
  
  #vidatech-admin-buildings .cell-container .table-cell .microtik-password .password.loading span:nth-child(3) {
    animation-delay: 0.2s;
  }
  
  #vidatech-admin-buildings .cell-container .table-cell .microtik-password .password.loading span:nth-child(4) {
    animation-delay: 0.3s;
  }
  
  @keyframes fade-in-out {
    0%, 100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }

/* Skeleton Loader Styles */
#vidatech-admin-buildings .skeleton {
    background-color: #e0e0e0;
    min-height: 40px;
    width: 100%;
    animation: shimmer 4s infinite linear;
}

@keyframes shimmer {
    0% {
        background-position: -1000px 0;
    }
    100% {
        background-position: 1000px 0;
    }
}

#vidatech-admin-buildings .skeleton-row {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
}

#vidatech-admin-buildings .skeleton-cell {
    width: 100%;
    margin-right: 3px;
}

#vidatech-admin-buildings .skeleton-container {
    margin-top: 60px;
}