.action>button {
    all:unset;
    display: block;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
}
.action>button>img {
    width: 25px;
}
.action>:focus {
    outline: 2px solid var(--bilbao); /* Focus outline for accessibility */
    outline-offset: 2px;
}

.action>button>img:hover {
    filter: invert(54%) sepia(13%) saturate(5290%) hue-rotate(168deg) brightness(92%) contrast(81%);
}

.action-options {
    margin-top: 10px;
    background-color: #F2F2F2;
    padding: 8px;
    border: 1px solid #F2F2F2;
    box-shadow: 0 1px 14px rgba(0,0,0,.2);
    color: #616161;
    font-size: 13px;
    position: absolute;
    z-index: 2;
    right: 1%;
}

.action-option {
    cursor: pointer;
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.action-option>p {
    margin: 3px 0px;
    font-size: 0.75em;
    color: black;
    font-weight: bold;
}

.action-option>p:hover {
    color: green;
}
  
.action-option>img {
    margin-right: 8px;
    margin-left: 8px;
    width: 10px;
}

.action {
    position: relative;
}
