.voip-line-modal .modal-body {
    overflow: hidden;
}

.voip-line-modal .modal-content {
    height: 100%;
}

.voip-line-modal form {
    display: flex;
    height: 100%;
    width: 100%;
}

.voip-line-modal .error-modal .modal {
    width: 400px;
    height: 200px;
    max-width: 95%;
    max-height: 95%;
}

#sip-form-container {
    width: 100%;
    color: black;
    margin-left: 30px;
    margin-right: 30px;
    overflow-y: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
}

#sip-form-header {
    display: flex;
    justify-content: flex-end;
}

#sip-form-header .create-button {
    margin: 0;
}

#sip-card-container {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    padding: 10px 0;
    height: 100%;
    overflow-y: auto;
    scrollbar-gutter: stable both-edges;
    scrollbar-width: thin;
    scrollbar-color: var(--bilbao) white;
}

#sip-card-container>.sip-card {
    color: #00142b;
    padding: 30px;
    box-shadow: 0px 4px 10px #00072B29;
    width: 410px;
}

.sip-card>h2 {
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    padding-bottom: 5px;
    border-bottom: 1px solid #7F7F7F;
    margin-bottom: 12px;
}

#sip-card-container>.sip-card .form-group-input, #sip-card-container>.sip-card .form-control {
    width: 225px;
    height: 30px;
}

#sip-form-container #sip-card-container .sip-card .sip-fields-container {
    opacity: 1;
    height: 294px;
    transition: height 0.3s, opacity 0.3s;
}

#sip-form-container #sip-card-container .sip-card .sip-fields-container.hidden {
    visibility: hidden;
    opacity: 0;
    height: 0px;
    transition: height 0.3s, opacity 0.15s, visibility 0.3s;
}

#sip-form-container #sip-card-container .sip-card .form-group-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    width: 100%;
}

#sip-form-container #sip-card-container .sip-card .sip-fields-container>.form-group-input>label.titreInput {
    font-size: 12px;
    margin: 0;
}

#sip-form-container #sip-card-container .sip-card>.form-group-input>label.titreInput {
    font-size: 14px;
    font-weight: normal;
    margin: 0;
}

#sip-form-container .error-messages {
    position: absolute;
    top: 25px;
    left: 0px;
}

#sip-form-container #sip-card-container .sip-card .select-control {
    height: 30px;
    width: 115px;
}

#sip-form-container #sip-card-container .sip-card .sip-card-buttons {
    display: flex;
    justify-content: center;
    gap: 15px;
    border-top: 1px solid #7F7F7F;
    padding-top: 20px;
}

#sip-form-container #sip-card-container .sip-card .sip-card-buttons .delete-sip-button {
    background-color: unset;
    border: none;
}

#sip-form-container #sip-card-container .sip-card .sip-card-buttons .vidatech-button {
    margin: 18px 0 0 0;
}

#sip-form-container button.vidatech-button {
    margin: 0 0 0 auto;
    width: 140px;
}