.back-button {
    all: unset;
}

.back-button {
    display: flex;
    cursor: pointer;
    margin: 0;
}


.back-button > div {
    padding: 11px;
}

.text {
    width: 150px;
}

.text, .back-arrow {
    color: #fff;
    background-color: #3D7E1C;
}

.back-button:hover .text, .back-button:hover .back-arrow {
    background-color: #52A628;
}

.back-arrow {
    margin-right: 2px;
    display: flex;
}

.back-arrow>img {
    margin: auto;
    width: 20px;
    height: 100%;
}

.text>p {
    padding-top: 4px;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
}