
.form-group-input {
  margin-bottom: 20px;
  position: relative;
  width: 390px;
}

.has-error {
  margin-bottom: 5px;
}

.form-control {
  color: #00142b;
  font-family: "Roboto", Helvetica;
  font-size: 12px;
  font-weight: 400;
  background-color: #f2f2f2;
  height: 45px;
  width: 390px;
  padding-left: 20px;
  border-radius: 0;
  border: none;
  margin: 0;
}

.error {
  color: #C21123;
  margin-top: 5px;
  font-size: 10px;
}

.titreInput {
  color: #00142b;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 14px;
  white-space: nowrap;
  margin-bottom: 10px;
}

.checkbox-container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  margin-top: 12px;
}

.checkbox-container .titreInput {
  margin: 0;
}

.checkbox-container input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  margin-right: 0.5em;
  border: 2px solid #3D7E1C;
  outline: none;
  cursor: pointer;
}

.checkbox-container input.checked {
  border: 2px solid #209AD6;
  position: relative;
}

.checkbox-container input.checked::before {
  content: "";
  display: block;
  background-color: #209AD6;
  height: 66%;
  width: 66%;
  position: absolute;
  right: 50%;
  top: 50%;
  transform: translate(50%, -50%);
}

.select-control {
  color: #00142b;
  font-family: "Roboto", Helvetica;
  font-size: 12px;
  padding-left: 20px;
  font-weight: 400;
  background-color: #f2f2f2;
  height: 40px;
  width: 390px;
  display: block;
  border: none;
  cursor: pointer;
}

.form-group-input>.tooltip-container>.tooltip-icon {
  width: 12px;
}

.form-group-input>.tooltip-container {
  position: absolute;
  top: 17px;
  right: -5px;
}

.form-group-input>.tooltip-container>.tooltiptext {
  visibility: hidden;
  font-size: 12px;
  background-color: var(--midnight);
  color: #fff;
  text-align: justify;
  padding: 10px;
  width: 240px;
  position: absolute;
  z-index: 1;
  top:20px;
  right:0px;
  margin-left:-50px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container .tooltiptext::before {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 97.5%;
  margin-left: -7px;
  border-width: 7px;
  border-style: solid;
  border-color: transparent transparent var(--midnight) transparent;
}

.form-group-input>.tooltip-container:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.form-group-input>input[type="radio"] {
  color: #00142b;
  font-size: 12px;
  font-weight: 400;
  background-color: unset;
  height: 45px;
  width: 390px;
  padding-left: 20px;
  border-radius: 0;
  border: solid 1px green;
  margin: 0;
}

.password-input-container {
  position: relative;
}

.password-icon {
  position: absolute;
  right: 10px; 
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.form-group-input.password-input-container input {
  padding-right: 35px;
}