#page-name-field .form-control,
#special-message-field .form-control {
    padding: 16px 18px;
    width: 100%;
    max-width: unset;
}

#right-panel .page-properties .form-group-input {
    width: 100%;
}

#visual-content #right-panel .property-options #orientation-field h5 {
    margin-bottom: 0;
}

#right-panel #panel-content.page-properties>div {
    max-height: 100%;
}

#right-panel .page-properties .radio-label {
    font-weight: normal;
    font-size: 12px;
}