.form-header {
  display: flex;
  justify-content: space-between;
}

#responder-form .titreInput {
  display: block;
  text-align: left;
}

#schedule {
  overflow: auto;
  scrollbar-width: thin;
}

#responder-form .form-title {
  border-bottom : 1px solid #7F7F7F;
  padding-bottom: 12px;
  margin: 20px 0 0;
}

.form-header .form-control {
  margin-top: 12px;
  width: 300px;
}

#responder-form .form-group-input {
  width: auto;
}

#responder-form button {
  float: right;
}

.day {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
}

.day-title {
  box-shadow: 0px 4px 10px #00072B29;
  padding: 6px 12px;
  margin: 0;
  width: 300px;
}

.time-selection {
  display: flex;
  margin-right: 20px;
}

.time-selection .form-group-input {
  margin-bottom: 10px;
  width: auto;
}


.time-selection input {
  width: 85px;
  margin-top: 10px;
  text-align: center;
  font-weight: 600;
  padding: 0;
  height: 35px;
}

.time-selection input::placeholder {
  font-weight: normal;
}

.time-selection img {
  margin: 10px 12px 0 12px;
}

.time-selection img[alt="plus"] {
  cursor: pointer;
  margin-top: 0;
}

.time-selection img[alt="delete"] {
  cursor: pointer;
  height: 15px;
  margin-top: auto;
  margin-bottom: auto;
}

.time-selection img[alt="plus"]:hover, .time-selection img[alt="delete"]:hover {
  filter: invert(37%) sepia(44%) saturate(706%) hue-rotate(56deg) brightness(100%) contrast(97%);
}

.availability {
  width: 50%;
}

.availability .form-group-input {
  display: flex;
  flex-direction: row-reverse;
  justify-content:flex-end;
  margin-top: 12px;
}

#responder-form>.form-header .form-control {
  margin-right: 10px;
}