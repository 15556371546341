.google-suggestion-container {
    background-color: #fff;
    position: absolute!important;
    z-index: 1000;
    border-radius: 2px;
    border-top: 1px solid #d9d9d9;
    font-family: Arial,sans-serif;
    -webkit-box-shadow: 0 2px 6px rgba(0,0,0,.3);
    box-shadow: 0 2px 6px rgba(0,0,0,.3);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
    width: 390px;
    max-height: 300px;
}

.google-suggestion-item {
    cursor: default;
    padding: 0 4px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 30px;
    text-align: left;
    border-top: 1px solid #e6e6e6;
    font-size: 11px;
    color: #515151;
}

.google-suggestion-item:hover {
    background-color: #f5f5f5;
}

.google-suggestion-matched {
    font-weight: bold;
    color: #000;
}