.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.modal {
    display: flex;
    flex-direction: column;
    background: #fff;
    margin: auto;
    box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.2);
    max-width: 50%;
    min-width: 20%;
    max-height: 80vh;
    min-height: 20vh;
    width: 600px;
}

/*resize modals*/
.error-modal .modal {
    height: 16em;
}

.edit-modal .modal {
    height: 512px;
    width: 453px;
}

/*temporary styling :*/
.edit-responder-modal .modal {
    width: 80%;
    height: 90%;
    max-width: 90%;
    max-height: 90%;
}

.confirm-modal .modal {
    width: 480px;
    height: 256px;
    overflow: hidden;
}

.modal-banner {
    background-color: #00142B;
    display: flex;
}

.modal-banner>h1 {
    color: white;
    font-size: 20px;
    margin: 20px 5px 20px 25px;
    text-transform: uppercase;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.close-button {
    align-self: flex-end;
    background: #3D7E1C;
    color: white;
    border: none;
    padding: 2px 3px 2px 3px;
    /* margin: 18px 25px 18px auto; */
    margin: auto 20px auto auto;

    cursor: pointer;
    font-size: 16px;
}

.close-button:hover,.modal-body button:hover {
    background: #52A628;
}

.modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    scrollbar-gutter: stable both-edges;
    scrollbar-width: thin;
    scrollbar-color: var(--bilbao) white;
}

.modal-body:hover {
    scrollbar-color: #52A628 white;
}

.modal-content {
    border: none;
    display: flex;
    align-items: center;
}
  
.modal-content>img {
    max-width: 10%;
    margin: 20px auto 5px auto;
}

.modal-content>p {
    padding: 10px;
    text-align: center;
    color: black;
}

.confirmation-modal .modal {
    width: 450px;
    height: 210px;
    font-size: 14px;
}

.confirmation-modal .modal-banner {
    height: 50px;
}

.confirmation-modal .modal .modal-content>div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 14px;
    text-align: center;
}

.confirmation-modal .modal .modal-content>div img {
    margin-top: 15px;
    height: 33px;
}

.confirmation-modal .loader-container>#loading-video {
    height: 100px;
    width: 100px;
}