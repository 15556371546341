.building-form {
    padding-top: 15px;
}

.building-form #button-container {
    order: 10;
    width: 390px;
    display: flex;
}

.building-form button {
    margin: auto 0 auto auto;
    /* order: 10; */
}

.edit-building-modal form {
    display: flex;
    width: fit-content;
    justify-content: center;
}

.create-building-modal form {
    width: auto;
}

.create-building-modal .modal {
    height: 810px;
    max-height: 90%;
    width: 450px;
}

.edit-building-modal .modal {
    height: 570px;
    width: 900px;
    max-width: 100%;
    max-height: 100%;
}

.building-form>* {
    max-width: 390px;
}

.edit-building-modal form,
.create-building-modal form {
    display: flex;
    flex-wrap: wrap;
    gap: 0 15px;
}

.building-form .form-group-input {
    flex: 1 0 100%; /* Initially set to single column */
    margin-bottom: 15px; /* Adjust as needed */
}

.edit-building-modal form .form-group-input {
    flex: 1 0 48%; /* Two columns when modal width is at least 850px */
}
.edit-building-modal form .name-input {
    order: 1;
}

.edit-building-modal form .phone-input {
    order: 3;
}

.edit-building-modal form .refresh-rate-input {
    order: 5;
}

.edit-building-modal form .address-input {
    order: 7;
}

/* .edit-building-modal form .apt-input {
    order: 9;
} */

.edit-building-modal form .city-input {
    order: 9;
}

.edit-building-modal form .state-input {
    order: 2;
}

.edit-building-modal form .postal-code-input {
    order: 4;
}

.edit-building-modal form .country-input {
    order: 6;
}

.edit-building-modal form .weather-input {
    order: 8;
    display: flex;
}

.edit-building-modal form .weather-input .checkbox-container {
    margin: auto 0;
}
