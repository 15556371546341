.confirm-user-modal .modal{
    width: 480px;
    height: 256px;
}

.confirm-user-modal .loader-container{
    margin: 20px;
}

.confirm-user-modal .vidatech-button {
    float: right;
}