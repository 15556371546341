#selected-item-details #selected-item-name {
    margin-bottom: 20px;
}

#selected-item-details .property-title {
    color: var(--midnight);
    font-size: 14px;
    font-weight: 500;
    border-bottom: 1px solid #7F7F7F;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

#selected-item-details .property-options>* {
    margin-bottom: 15px;
}

#selected-item-details .property-group {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
}

#selected-item-details .property-group .property-name{
    font-weight: 500;
}

/*animate item details*/

#file-explorer:has(.selected-item-details-enter),
#file-explorer:has(.selected-item-details-enter-active),
#file-explorer:has(.selected-item-details-exit),
#file-explorer:has(.selected-item-details-exit-active) {
    overflow: hidden;
}

.selected-item-details-enter {
    opacity: 0;
    transform: translateX(200px);
}

.selected-item-details-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}

.selected-item-details-exit {
    opacity: 1;
    transform: translateX(0);
}

.selected-item-details-exit-active {
    opacity: 0;
    transform: translateX(200px);
    transition: opacity 300ms, transform 300ms;
}

#file-explorer>#file-explorer-content>#selected-item-details {
    border-left: 1px solid #707070;
    font-size: 14px;
    flex-grow: 0;
}

#file-explorer>#file-explorer-content>#selected-item-details h2 {
    border-bottom: 1px solid #707070;
}

#file-explorer>#file-explorer-content>#selected-item-details>div {
    width: 300px;
    height: 100%;
    padding: 15px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--bilbao) white;
}

#file-explorer>#file-explorer-content>#selected-item-details>div:hover {
    scrollbar-color: #52A628 white;
}


#file-explorer #selected-item-preview {
    display: flex;
    margin-bottom: 30px;
}

#file-explorer #selected-item-preview img[alt="file-preview"] {
    --square-size: 10px;
    background-color: rgb(198, 198, 198);
    background-image:
      linear-gradient(45deg, #959595 25%, transparent 25%), 
      linear-gradient(135deg, #959595 25%, transparent 25%),
      linear-gradient(45deg, transparent 75%, #959595 75%),
      linear-gradient(135deg, transparent 75%, #959595 75%);
    background-size: var(--square-size) var(--square-size);
    background-position: 0 0, calc(var(--square-size)/2) 0, calc(var(--square-size)/2) calc(var(--square-size)/-2), 0 calc(var(--square-size) / 2);
    background-position: 0 0, 5px 0, 5px -5px, 0px 5px;
}

#file-explorer #selected-item-preview img, #file-explorer #selected-item-preview video {
    height: 160px;
    margin: auto;
}

#file-explorer #selected-item-preview img {
    max-height: 100%;
    max-width: 100%;
    object-fit: scale-down;
}