.callee-card-form {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
}

.callee-card-form img {
    height: 15px;
}

.callee-card-form>* {
    margin-left: 8px;
    margin-right: 8px;
}

.blue-background-class {
    background-color: #3d7e1c7c;
}

.delete-card {
    margin: 0;
    padding: 12px 8px;
}

.delete-card:hover {
    cursor: pointer;
}

.callee-card-form .tooltip-container {
    top: -8px;
}

.callee-card-form .form-group-input>.tooltip-container>.tooltiptext {
    top: 26px;
}

.callee-card-form .tooltip-container .tooltiptext::before {
  border-color: transparent  var(--midnight) var(--midnight) transparent;
  left: 95.5%;
}

.card-fields ul {
    position: absolute;
    top: 30px;
    left: 15px;
}