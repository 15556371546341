#link-visual-list {
    display: flex;
    width: 100%;
    flex-direction: column;
}

#link-visual-list button {
    margin: 0 20px 0 auto;
}

#link-visual-list .checkbox-container {
    margin: 0;
    width: auto;
}

#link-visual-list td:has(input[type="checkbox"]) {
    width: 10%;
}

#link-visual-list .checkbox-container input[type="checkbox"] {
    border: 2px solid #FFFFFF;
}

#link-visual-list .checkbox-container input.checked {
    border: 2px solid var(--curious-blue);
}

#link-visual-list .selected-visual {
    outline: 2px solid var(--curious-blue);
}

.link-visual-modal .error-message {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}