.radio-group {
    display: flex;
    flex-direction: row;
}

.radio-option {
    margin-right: 40px;
}

.radio-option>input[type="radio"] {
    appearance: none;
    width: 16px;
    height: 16px;
    border: 2px solid #3D7E1C;
    margin-right: 10px;
    position: relative;
    top: 2px;
    outline: none;
    cursor: pointer;
}

.radio-option>input[type="radio"]:checked {
    border: 2px solid #209AD6;
    position: relative;
}

.radio-option>input[type="radio"]:checked::before {
    content: "";
    display: block;
    background-color: #209AD6;
    transition: 0.5s all linear;
    height: 66%;
    width: 66%;
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translate(50%, -50%);
}

.radio-label {
    color: #00142b;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}