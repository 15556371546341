.loading-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

#loading-modal-content {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    height: 100%;
    width: 100%;
    /* background-color: white; */
}

#loading-modal-content>#loading-video {
    height: 275px;
    width: 275px;
}