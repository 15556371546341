#chatroom-container {
  display: flex;
  width: 100%;
}

#chatroom-container > div {
  flex: 1;
  padding: 0 20px;
}

#video-window {
  border: 1px solid var(--bilbao);
  max-width: 100%;
  margin-bottom: 20px;
}

.chatroom-title {
  box-shadow: 0px 4px 10px #00072B29;
  padding: 6px 12px;
  font-weight: 600;
  font-size: 20px;
  justify-content: center;
  margin-bottom: 20px;
}

.chatroom-subtitle {
  border-bottom : 1px solid #7F7F7F;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 2px;
  margin: 20px 0 0;
}

#chatroom-right {
  flex: 1;
}

#chatroom-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}

#video-window>* {
  max-width: 100%;
  max-height: 100%;
}

#first-responders-carousel {
  display: flex;
  justify-content: space-around;
}

#chatroom-right>.chatroom-title {
  display: flex;
  align-items: center;
}

#chatroom-right>.chatroom-title>h1 {
  text-align: center;
  margin: 0;
  flex:1;
}

#chatroom-left>.chatroom-title {
  text-align: center;
  width: 300px;
  margin-top: 32px;
}

.details-button {
  all: unset;
}

#communication {
  display: flex;
  position: relative;
  height: 25vh;
}

#communication form {
  display: flex;
  flex-direction: column;
  flex: 1;
}

#communication button {
  margin-left: auto;
}

#communication textarea {
  resize: none;
  border: 1px solid #7F7F7F;
  font-size: 12px;
  padding: 12px 9px;
  height: 60px;
  box-shadow: 0px 10px 12px 3px #2929291e;
}

.details {
  display: flex;
}

.details > div {
  flex: 1;
}

.info {
  display: flex;
  flex-direction: column;
}

.info>p:first-child, .chatroom-subtitle+p {
  color: #00142b;
  font-size: 14px;
  font-weight: 500;
  margin-top: 8px;
  margin-bottom: 10px;
}

.info>p:last-child {
  color: #00142b;
  font-size: 12px;
  background-color: #f2f2f2;
  margin-right: 10px;
  max-width: 250px;
  padding: 15px 20px;
}

#address {
  padding: 10px 20px;    
}

#conversation {
  overflow-y: auto;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

#first-responders-carousel {
  margin-bottom: 20px;
}

.first-responder {
  background-color: #f2f2f2;
  text-align: center;
  font-size: 12px;
  padding: 6px 40px;
  color: var(--midnight);
  margin: 0 12px;
  min-width: fit-content;
}

.responders-button {
  all:unset;
}

img[alt="previous-arrow"] {
  transform: rotate(180deg);
}

#communication.wide {
  flex-direction: column;
  justify-content: flex-start;
  height: unset;
  max-height: 70vh;
}

#communication.wide>form {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.wide textarea {
  width: 80%;
  margin-right: 10px;
}

#communication.wide #conversation {
  height: 20vh;
}

#communication.wide .vidatech-button {
  margin: auto 0 0 auto;
}

@media (max-width: 1250px) {
  #chatroom-container {
    flex-direction: column;
    
  }
  #chatroom-left {
    width: 100%;
  }
}