.items-table-container {
    padding: 0px 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between
}

.items-table-container:hover {
    scrollbar-color: #52A628 white;
}

.items-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 1em;
    font-size: 14px;
}

.table-cell {
    text-align: center;
}

tbody .table-cell {
    max-width: 250px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.items-table > thead {
    position: sticky;
    top: 0;
    z-index: 2; /* Increase z-index to overlay body scroll */
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.205);
}

.items-table > thead > tr > th {
    color: #9F9F9F;
    padding: 10px 0;
}

.items-table > tbody {
    background-color: white;
}

.items-table > tbody > tr {
    background-color: var(--midnight);
}

.items-table td,
.items-table th {
    vertical-align: middle;
}

.items-table > tbody > tr > td {
    padding: 9px 0;
}

.items-table > tbody > tr > td .table-cell {
    color: white;
    padding: 9px 0;
}

.table-cell {
    padding: 0px 2px;
}

.cell-container > div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.cell-container .table-cell {
    margin: auto;
}

.separator {
    content: "";
    display: inline;
    height: 31px;
    border-right: #9F9F9F 1px solid;
    margin-top: auto;
    margin-bottom: auto;
}

thead .table-cell {
    border-right: #F2F2F2 3px solid;
    padding: 2px 2px 5px 0px;
}

thead .table-cell.sort-by {
    cursor: pointer;
}

thead > tr > th:last-child .table-cell {
    border-right: none;
}

.items-table .sort-by { 
	padding-right: 18px;
	position: relative;
}
.items-table .sort-by:before,
.items-table .sort-by:after {
	border: 4px solid transparent;
	content: "";
	display: block;
	height: 0;
	right: 5px;
	top: 50%;
	position: absolute;
	width: 0;
}
.items-table .sort-by:before {
	border-bottom-color: #9F9F9F;
	margin-top: -9px;
}
.items-table .sort-by:after {
	border-top-color: #9F9F9F;
	margin-top: 1px;
}

.items-table .sort-by.asc:after {
    display: none;
}

.items-table .sort-by.desc:before {
    display: none;
}

.items-table .table-cell .checkbox-container {
    margin: auto;
    width: auto;
    justify-content: center;
}

.items-table .table-cell .checkbox-container input[type="checkbox"] {
    margin-right: unset;
}

.items-table .table-cell.checkbox-cell {
    padding: 0;
}