.status-icon {
  background-color: white;
  border-radius: 7.5px;
  height: 15px;
  width: 15px;
  margin: auto;
}

.green-status {
  background-color: green;
}

.red-status {
  background-color: #c21123;
}

tr.building-row:hover{
  cursor: pointer;
  background-color: var(--curious-blue);
}

.building-row .action>img:hover {
  cursor: pointer;
  filter: invert(10%) sepia(17%) saturate(3074%) hue-rotate(174deg) brightness(12%) contrast(108%);
}