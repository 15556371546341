.information-sheet-modal .modal{
  width: 900px;
  max-width: 80%;
  height: 500px;
}

.voip-line-modal .modal, #callees-modal .modal {
  width: 1440px;
  height: 900px;
  max-width: 95%;
  max-height: 95%;
}

.custom-voip-line-modal .modal {
  height: 740px;
  max-height: 95%;
}

.link-visual-modal .modal{
  width: 800px;
  max-width: 80%;
  max-height: 95%;
  height: 400px;
}

.form-product-modal .modal {
  width: 450px;
  height: 520px;
}

.product-form .form-control, .product-form .select-control {
  width: 390px;
}

.product-form .vidatech-button {
  float: right;
  margin-bottom: 0;
}

.product-form .tooltip-container .tooltiptext {
  top: 26px
}

.product-form .tooltip-container .tooltiptext::before {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 95.5%;
  margin-left: -7px;
  border-width: 7px;
  border-style: solid;
  border-color: transparent  var(--midnight) var(--midnight) transparent;
}
