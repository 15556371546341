#file-explorer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 99%;
}

.modal #file-explorer {
    height: 100%;
}

.modal-body:has(#file-explorer) {
    padding: 0;
}

#file-explorer #content-view-footer {
    display: flex;
    align-items: center;
    font-size: 12px;
    padding-top: 10px;
    margin: auto 20px 20px 20px;
}

#file-explorer>#file-explorer-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #707070;
}

#file-explorer>#file-explorer-content {
    display: flex;
    justify-content: space-between;
    height: calc(100% - 80px);
    max-width: 100%;
}

#file-explorer #explorer-content-left {
    width: 100%;
}

#file-explorer>#file-explorer-content>div {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
}

#file-explorer>#file-explorer-content>div>#content-view {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    height: 100%;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--bilbao) white;
}

#file-explorer > #file-explorer-content > div > #content-view:hover {
    scrollbar-color: #52A628 white;
}

#file-explorer>#file-explorer-header>#address-bar {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 20px;
    width: 70%;
    height: 40px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.205);
}

#file-explorer>#file-explorer-header>#address-bar #address-bar-buttons {
    margin: 12px 12px 12px auto;
    display: flex;
    align-items: center;
    gap: 25px;
}

#file-explorer>#file-explorer-header>#address-bar #add-folder {
    height: 25px;
    padding-left: 20px;
    border-left: solid 3px #F2F2F2;
    display: flex;
}

#file-explorer>#file-explorer-header>#address-bar #add-folder>button {
    background-color: transparent;
    margin: auto 0;
    border: none;
}

#file-explorer>#file-explorer-header>#address-bar #add-folder>button:hover>img,
#file-explorer>#file-explorer-header>#address-bar #back-button:hover img {
    filter: invert(36%) sepia(83%) saturate(3359%) hue-rotate(73deg) brightness(87%) contrast(78%);
}

#file-explorer>#file-explorer-header>#address-bar #add-folder>button:disabled>img,
#file-explorer>#file-explorer-header>#address-bar #add-folder>button:disabled:hover>img {
    filter: invert(100%) sepia(6%) saturate(490%) hue-rotate(215deg) brightness(118%) contrast(90%);
}

#file-explorer>#file-explorer-header>#address-bar #back-button {
    border: none;
    background-color: transparent;
}

#file-explorer>#file-explorer-header>#address-bar #back-button img {
    height: 18px;
}

#file-explorer>#file-explorer-header>#address-bar #add-folder img {
    height: 13px;
}

#file-explorer>#file-explorer-header>#address-bar #path-name {
    font-size: 12px;
}

#file-explorer #import-button {
    justify-self: flex-end;
}

#file-explorer #import-button .vidatech-button{
    height: 25px;
    margin: 0;
}

#file-explorer>#file-explorer-header>#search-bar {
    display: flex;
    gap: 6px;
    margin: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.205);
    font-size: 14px;
}

#file-explorer>#file-explorer-header>#search-bar>input {
    all: unset;
}

#file-explorer>#file-explorer-header>#search-bar>img#search-icon {
    height: 22px;
    margin: 9px;
}

#folder-error {
    color: red;
    font-size: 9px;
    position: absolute;
    width: 100px;
    left: 0;
    top: 125px;
}

#file-explorer>#file-explorer-content>div>#content-view #create-folder textarea.file-name {
    max-width: 80px;
    outline: none;
    border: none;
    background-color: transparent;
    resize: none;
    overflow: hidden;
}

#file-explorer>#file-explorer-content>div>#content-view #create-folder textarea.file-name:focus {
    outline: solid 1px var(--curious-blue);
    border: none;
    text-align: justify;
}

#content-view-footer #progress-bar {
    margin: 0 10px;
    width: 280px;
    height: 16px;
    background-color: var(--midnight);
}

#content-view-footer #progress-bar #progress-bar-fill {
    background-color: #73B74E;
    max-width: 100%;
    height: 100%;
}