#main:has(>#main-content #contractor-page) {
    min-width: 920px;
    align-self: center;
    max-height: 550px;
    margin-top: 50px;
}

#contractor-page {
    display: flex;
    flex-direction: column;
}

#contractor-confirmation .modal {
    height: 200px;
    width: 450px;
}

#contractor-confirmation .modal-content>p {
    padding: 0;
    font-size: 12px;
}

#contractor-confirmation .modal-content>img {
    margin-bottom: 12px;
}
