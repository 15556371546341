.pagination {
    display: flex;
    justify-content: center;
    padding-bottom: 5px;
}

.pagination ol {
    display: flex;
    list-style: none;
}

.pagination ol li {
    width: 20px;
    margin: 0 2px;
}

.pagination ol li {
    text-align: center;
}

.pagination button.pagination-button {
    border: none;
    background-color: unset;
}

.pagination button.pagination-button.active {
    background-color: var(--bilbao);
    border-color: #007bff;
    color: #fff;
}