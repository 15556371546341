#type-field .select-control {
    width: auto;
}

#custom-button {
    display: flex;
    flex-direction: column;
}

#custom-button .modal {
    max-width: 90%;
    width: 90%;
    max-height: 95%;
    height: 95%;
}

.import-custom-button {
    display: flex;
    align-items: center;
    background-color: #f2f2f2;
    margin-bottom: 10px;
    height: 45px;
}

.import-custom-button>button {
    background-color: unset;
    border: none;
    margin-left: auto;
    padding: 0;
    height: 100%;
}

.import-custom-button>img {
    max-height: 100%;
    max-width: 40px;
    padding: 5px;
}

#right-panel #button-name-field .form-group-input, #button-name-field .form-control {
    max-width: unset;
    margin: 0;
}


.button-properties #file-explorer .modal {
    max-width: 50%;
    min-width: 20%;
    max-height: 80vh;
    min-height: 20vh;
    width: 600px;
    height: 500px;
}

.import-custom-button>img:hover {
    cursor: pointer;
}