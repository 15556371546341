.language-slider-container {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Arial, sans-serif;
}

.language-slider {
    position: relative;
    width: 64px;
    height: 28px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    overflow: hidden;
}
  
.slider-french {
    background-color: var(--bilbao); /* Green */
}
  
.slider-english {
    background-color: var(--curious-blue); /* Blue */
}
  
.thumb {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 24px;
    height: 24px;
    transition: left 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    z-index: 2;
}
  
.slider-french .thumb {
    left: 2px;
}
  
.slider-english .thumb {
    left: calc(100% - 26px);
}
  
.thumb-image {
    width: 24px;
    height: 24px;
}
  
.language-label {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 5px;
    transition: right 0.3s ease, left 0.3s ease;
    font-size: 12px;
    color: white;
    z-index: 1;
}
  
.slider-french .language-label {
    right: 4px;
    left: auto;
}
  
.slider-english .language-label {
    left: 4px;
    right: auto;
}