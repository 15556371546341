.appStyle .amplify-button--primary {
    border-radius: 0%;
    background-color: var(--bilbao);
}

.appStyle .amplify-button--primary:hover {
    background-color: #52a628;
}

.appStyle .amplify-input, .appStyle .amplify-field-group__outer-end .amplify-field-group__control {
    border-radius: 0%;
    background-color: #f2f2f2;
    border: none;
    font-size: 12px;
    height: 45px;
}

.appStyle .amplify-tabs-item[data-state=active] {
    background-color: var(--bilbao);
}

.appStyle .amplify-tabs-item[data-state=active]:hover {
    color: white;
}

.appStyle .amplify-tabs-item[data-state=inactive]:hover {
    color: #52A628;
}