#widgets-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
    max-height: 40%;
    overflow-y: auto;
    scrollbar-gutter: stable both-edges;
    scrollbar-width: thin;
    scrollbar-color: var(--bilbao) white;

}

.widget-button {
    padding: 0;
    width: 100px;
    height: 50px;
    display: flex;
    border: 1px solid var(--bilbao);
    position: relative;
    
    --square-size: 10px;
    background-color: rgb(198, 198, 198);
    background-image: linear-gradient(45deg, #959595 25%, transparent 25%), 
    linear-gradient(135deg, #959595 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #959595 75%),
    linear-gradient(135deg, transparent 75%, #959595 75%);
    background-size: var(--square-size) var(--square-size);
    background-position: 0 0, calc(var(--square-size)/2) 0, calc(var(--square-size)/2) calc(var(--square-size)/-2), 0 calc(var(--square-size) / 2);
    background-position: 0 0, 5px 0, 5px -5px, 0px 5px;
}

.widget-button.selected {
    border: 2px solid var(--bilbao);
}

.widget-button.selected::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(82, 166, 40, 0.33);
    pointer-events: none;
}

.widget-image {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
}

.skeleton-image {
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
}

.widget-meteo-properties .warning-message {
    display: flex;
    align-items: center;
    color: var(--error-red);
}

.widget-meteo-properties .error-messages>p {
    margin: 10px auto;
    color: var(--error-red);
}

.widget-meteo-properties .warning-message>p {
    margin: auto 0 0 10px;
}

@keyframes shimmer {
    0% {
        background-position: -200% 0;
    }
    100% {
        background-position: 200% 0;
    }
}