#footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid var(--curious-blue);
    margin: auto 20px 20px 20px;
    font-family: "Roboto", Helvetica;
    font-size: 12px;
    box-shadow: 0px 10px 20px grey;
}

#footer #footer-left {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin: 20px 20px 20px 50px;
}

#footer>#footer-right {
    margin-right: 50px;
}

#footer>#footer-left>img[alt="Vidatech Logo"] {
    height: 76px;
}

#footer>#footer-left>#about-info {
    display: flex;
}

#footer>#footer-left:has(+ #elevator-id)>#about-info {
    border-right: 1px solid #FFFFFF;
    padding-right: 50px;
}

#footer>#footer-left>#about-info>a {
    align-self: flex-end;
}

#footer>#footer-left>#about-info>div>* {
    margin-top: 6px;
}

#footer>#footer-left>#about-info>div>div>a {
    color: white;
}

#footer>#footer-left>#about-info>div>h2 {
    font-size: 14px;
    font-weight: bold;
}

#footer>#elevator-id {
    margin-right: auto;
    font-size: 20px;
}