#error-page {
    height: 100vh;
    display: flex;
}

#error-page>#error-container {
    margin: auto;
    background-repeat: no-repeat;
    background-position: center;
}

#error-page>#error-container.error-401 {
    background-image: url("../../img/Error401.svg");
    background-size: 912px 418px;
    width: 1200px;
    height: 418px;
}

#error-page>#error-container.error-404 {
    background-image: url("../../img/Error404.svg");
    background-size: 498px 564px;
    width: 1200px;
    height: 564px;
}

#error-page>#error-container>div {
    width: 100%;
    height: 100%;
    color: #00142B;
    font-size: 20px;
    text-transform: uppercase;
    margin-top: 40px;
    margin-left: 40px;
}

#error-page>#error-container.error-404>div {
    padding-top: 150px;

}

#error-page>#error-container>div>h1 {
    font-size: 40px;
    font-weight: 900;
    margin: 0;
    margin-bottom: 17px;
}

#error-page>#error-container>div>p {
    font-weight: 500;
    margin: 0;
    margin-bottom: 12px;
}

#error-page>#error-container.error-404>div>p {
    width: 340px;
}