:root {
    --visual-header-height: 72px;
    --main-padding: 20px;
    --border-width: 1px;
}

#visual-content {
    --max-height: calc(100vh - (var(--visual-header-height)) - (var(--main-padding) * 2) - (var(--border-width) * 2));
    display: flex;
    justify-content: space-between;
    background-color: #7F7F7F;
    color: var(--midnight);
    font-size: 12px;
    flex-grow: 1;
    max-height: var(--max-height);
    height: 100%;
}

#edit-visual-header {
    height: var(--visual-header-height);
}

#edit-visual-header #preview-mode-title {
    margin: auto 35px auto auto;
    height: 25px;
    width: 360px;
    box-shadow: 0px 4px 6px #00072B29;
}

#edit-visual-header #preview-mode-title>h2 {
    margin-left: auto;
    margin-right: auto;
    font-size: 10px;
    color: #00142B;
}

#right-panel,
#left-panel {
    border: solid 1px var(--bilbao);
    background-color: white;
    width: 350px;
    min-width: 360px;
}

#canvas-container {
    overflow: auto;
    scrollbar-gutter: stable;
    flex-grow: 1;
    display: flex;
    scrollbar-width: thin;
    scrollbar-color: var(--bilbao) white;
}

#canvas {
    background-color: #9e9e9e;
    margin: auto;
}

#edit-visual-header {
    display: flex;
    justify-content: space-between;
    border: solid 1px var(--bilbao);
}

#edit-visual-header>div {
    display: flex;
    align-items: center;
}

#edit-visual-header #visual-info {
    margin-left: 20px;
}

#edit-visual-header #visual-info h1 {
    font-size: 20px;
    font-weight: 600;
    font-style: italic;
    text-transform: uppercase;
    margin-bottom: 5px;
}

#edit-visual-header #visual-info h2 {
    text-transform: uppercase;
}

#header-button-zone>.vidatech-button {
    margin: 15px 15px 15px 15px;
}

#header-button-zone>.create-button {
    margin-left: 15px;
}

#header-button-zone .checkbox-container {
    margin-top: 0px;
}

#header-button-zone #snap-to-middle-checkbox {
    margin-left: 10px;
}

#edit-visual-header .back-button {
    height: 100%;
}

#edit-visual-header .back-arrow {
    height: 100%;
    width: 68px;
}

.edit-visual-pannel {
    display: flex;
    flex-direction: column;
    width: 320px;
    min-width: 320px;
    max-width: 360px;
}

.panel-banner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--midnight);
}

.edit-visual-pannel>div:first-child {
    border-bottom: solid 1px var(--bilbao);
}

.edit-visual-pannel h3 {
    color: white;
    font-size: 20px;
    padding: 14px 12px 12px 20px;
}

#visual-content .error-modal .modal {
    height: 280px;
}

#visual-content #canvas-container #zoom-level {
    position: sticky;
    top: 0;
    right: 0;
    z-index: 1;
}

#visual-content #canvas-container #zoom-level #zoom-controls {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    width: 90px;
    background-color: #bdbdbd67;
    opacity: 0.40;
    transition: opacity 0.3s;
}

#visual-content #canvas-container #zoom-level #zoom-controls:hover {
    opacity: 1;
    box-shadow: 0 3px 6px #00000029;

}

#visual-content #canvas-container #zoom-level #zoom-controls #zoom-buttons {
    display: flex;
}

#visual-content #canvas-container #zoom-level #zoom-controls #zoom-buttons button {
    background-color: var(--bilbao);
    color: white;
    border: none;
    padding: 5px;
    width: 20px;
    height: 20px;
    margin: 4px;
    font-size: 12px;
    cursor: pointer;
}

#visual-content #canvas-container #zoom-level #zoom-controls p {
    color: white;
    margin-left: 2px;
}

#visual-content .error-modal ul {
    list-style-type: disc;
}

#visual-content .error-modal ul>li {
    margin-bottom: 5px;
}

#visual-content .error-modal .vidatech-button {
    min-height: 40px;
}
