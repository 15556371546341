.loader-container {
    display: flex;
    height: 100%;
    justify-content: center;
    margin: auto;
}

/* .spinner {
    width: 64px;
    height: 64px;
    border: 8px solid;
    border-color: #3d5af1 transparent #3d5af1 transparent;
    border-radius: 50%;
    animation: spin-anim 1.2s linear infinite;
} */

.loader-container>#loading-video {
    height: 250px;
    width: 250px;
    margin: auto;
}

@keyframes spin-anim {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}