.model {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
}
.display-block {
  display: block;
}
.display-none {
  display: none;
}
.infoPage{
  position:fixed;
  background: white;
  width: 30%;
  height: 70%;
  max-height: 400px;
  min-width: 800px;
  min-height: 335px;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  display: flex;
  flex-direction: column;
  color: var(--midnight);
}

.infoHeader{
  align-items: center;
  color: white;
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: space-between;
  background-color: var(--primary-darkblue-color);
  padding: 15px;
}

.infoClose{
  background-color: var(--bilbao);
  width: 20px;
  height: 20px;
}

.submitBtn.danger {
  border: 1px solid red;
  background-color: red;
  color: white;
}
.product-info {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 7px;
  min-height: 66px;
  width: 210px;
  justify-content: center;
  margin-bottom: 15px;
}

.ip-address {
  color: var(--midnight);
  font-weight: 500;
  line-height: 14px;
  margin-top: 1px;
  min-height: 18px;
  white-space: nowrap;
}

.overlap {
  align-items: center;
  background-color: #f2f2f2;
  display: flex;
  height: 40px;
  min-width: 210px;
  padding: 0 20px;
}

.text-1 {
  color: var(--midnight);
  font-weight: 400;
  height: 18px;
  line-height: 14px;
  width: 162px;
}
.contenerClass{
  display: flex;
  margin-top: 30px;
}
.col2{
  margin-left: 30px;
  margin-right: 30px;
}
.col1{
  margin-left: 50px;
}


