#account-form {
    padding: 20px;
    width: 430px;
}

#account-form .underlined-title {
    color: var(--midnight);
    font-size: 16px;
    font-weight: 500;
    border-bottom: 1px solid #7F7F7F;
    padding-bottom: 2px;
    margin-bottom: 12px;
}

#account-form .password-icon {
    position: relative;
    top: 35px;
    right: 25px;
}

#account-form .vidatech-button {
    margin: 10px 0;
}

#account-form .form-group-input.password-input-container .tooltip-container {
    margin-left: 0;
}

#account-form .tooltip-container>.tooltiptext {
    width: 225px;
}

#account-form .form-group-input.password-input-container .tooltip-container .span.tooltiptext.tooltipmessages>div {
    padding-top: 12px;
}